<template>
  <div>
    <div class="row justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center px-5 pt-5">
      <div class="col-xl-auto col-lg-auto col-md-auto col-12 text-center">
        <div>
          <button
            @click="$router.push({ name: 'login' })"
            class="btn btn-warning d-flex justify-content-center align-items-center"
          >
            <i class="bi bi-caret-left-square fs-4"></i>
            <span class="m-2"> Retour </span>
          </button>
        </div>
      </div>
      <div class="col-xl-auto col-lg-auto col-md-auto col-12 text-center">
        <span> Vous avez la référence : </span>
        <span class="fw-bold fs-4">
          {{ this.$route.params.reference }}
        </span>
        ?
      </div>
    </div>
    <div class="login-password">
      <div class="row card-keyboard-value">
        <div class="col d-flex justify-content-between align-items-center p-0">
          <input
            :type="password"
            class="fw-bold fs-3 ps-4"
            :value="valueKeyboard"
          />
          <!-- <span class="fw-bold fs-3 ps-4">
            {{ valueKeyboard }}
          </span> -->
          <div class="col-auto">
            <button
              class="btn border-0"
              @click="
                password == 'password'
                  ? (password = 'text')
                  : (password = 'password')
              "
            >
              <i
                v-show="password == 'password'"
                class="bi bi-eye-fill text-warning fs-1"
              ></i>
              <i
                v-show="password == 'text'"
                class="bi bi-eye-slash-fill text-warning fs-1"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row fw-bold">
        <div class="col-3">
          <button @click="keyEvent('', '7')" class="btn btn-keyboard">7</button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('', '8')" class="btn btn-keyboard">8</button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('', '9')" class="btn btn-keyboard">9</button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('', '')" class="btn btn-keyboard">0</button>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <button @click="keyEvent('', '4')" class="btn btn-keyboard">4</button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('', '5')" class="btn btn-keyboard">5</button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('', '6')" class="btn btn-keyboard">6</button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('', 'C')" class="btn btn-keyboard">C</button>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <button @click="keyEvent('1', '1')" class="btn btn-keyboard">
            1
          </button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('2', '2')" class="btn btn-keyboard">
            2
          </button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('3', '3')" class="btn btn-keyboard">
            3
          </button>
        </div>
        <div class="col-3">
          <button @click="keyEvent('.', '.')" class="btn btn-keyboard">
            .
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      valueKeyboard: "",
      password: "password",
    };
  },
  methods: {
    keyEvent(key, value) {
      if (value == "C") {
        this.valueKeyboard = "";
        return;
      } else if (value == ".") {
        if (this.valueKeyboard.includes(".") == true) {
          return;
        } else if (this.valueKeyboard.includes(".") == false) {
          //   this.valueKeyboard = this.valueKeyboard + "" + value;
          return;
        }
      } else {
        this.valueKeyboard = this.valueKeyboard + "" + value;
        let reference = this.$route.params.reference;
        let password = this.valueKeyboard;
        this.$store.dispatch("auth/login", { password, reference });
      }
    },
  },
  computed: {},
  mounted() {
    if (this.$route.params.reference == "") {
      this.$router.push({ name: "login" });
    }
  },
};
</script>
<style scoped>
.btn-keyboard {
  width: 100%;
  background: #ff4f4f;
  margin: 20px 0px;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: xxx-large;
  height: 2em;
}
.card-keyboard-value {
  height: 7em;
  background: #5a425c;
  border-radius: 6px;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.card-keyboard-value div input {
  height: 75px;
  background: none;
  border: none;
  outline: none;
  border-radius: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}
.login-password {
  width: 35em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
}
@media screen and (max-width: 800px) {
  .btn-keyboard {
    width: 100%;
    background: #ff4f4f;
    margin: 15px 0px;
    padding: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: xx-large;
    height: 2em;
  }
  .card-keyboard-value {
    height: 5.5em;
    background: #5a425c;
    border-radius: 6px;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
  }

  .card-keyboard-value div input {
    height: 50px;
    background: none;
    border: none;
    outline: none;
    border-radius: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
  }
  .login-password {
    width: 22em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
  }
}
</style>
